<div style="position:relative"  [class.quote-grid-document]="!hasDocs">
  <table mat-table [dataSource]="rows">
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>Trial/Non-trial Number</th>
      <td mat-cell *matCellDef="let row">
        <a *ngIf="row.SourceSystemLink" href="{{row.SourceSystemLink}}" target="_blank">{{row.Number}}</a>
        <span *ngIf="!row.SourceSystemLink">{{row.Number}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="external">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.IsExternal === true">Ext</ng-container>
        <ng-container *ngIf="row.IsExternal === false">Int</ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="shortTitle">
      <th mat-header-cell *matHeaderCellDef="VendorTypeDescription">Trial Short Title / Non-trial Desc</th>
      <td mat-cell *matCellDef="let row">
        {{row.ShortTitle}}
      </td>
    </ng-container>
    <ng-container matColumnDef="commercial">
      <th mat-header-cell *matHeaderCellDef>Paid by Commercial</th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.IsCommercial === true">Yes</ng-container>
        <ng-container *ngIf="row.IsCommercial === false">No</ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="objective">
      <th mat-header-cell *matHeaderCellDef>Vendor Objective/Non-trial Work Product</th>
      <td mat-cell *matCellDef="let row">
        {{row.ObjectiveName}}
      </td>
    </ng-container>
    <ng-container matColumnDef="currency">
      <th mat-header-cell *matHeaderCellDef>Currency</th>
      <td mat-cell *matCellDef="let row">
        {{row.CurrencyName}}
      </td>
    </ng-container>
    <ng-container matColumnDef="totalCommitted">
      <th mat-header-cell *matHeaderCellDef style="text-align: right;">
        Total Committed
      </th>
      <td mat-cell *matCellDef="let row" style="text-align: right;">
        {{GetRowTotalCommitted(row) | number}}
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Trial Status</th>
      <td mat-cell *matCellDef="let row">
        {{row.Status}}
      </td>
    </ng-container>
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef>Document</th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="CanEditComment(row)">
          <mat-form-field style="width: 100px" matTooltip="{{isGridDisabled ? row.Comment : null}}">
            <input matInput name="by_comment_{{row.Id}}" [(ngModel)]="row.Comment" [disabled]="isGridDisabled" />
          </mat-form-field>
        </ng-container>
      </td>
    </ng-container>

    <!-- dynamically create the budget year columns. -->
    <ng-container *ngFor="let budgetYear of columnBudgetYears" matColumnDef="by_{{budgetYear}}">
      <th mat-header-cell *matHeaderCellDef>
        BY {{budgetYear}}
        Committed
        <br />
        <em>Exclude Tax, VAT etc.,</em>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row['by_' + budgetYear] !== undefined">
          <comma-separated-input [label]="row['by_' + budgetYear].QuoteNumber"
                         [(amount)]="row['by_' + budgetYear].QuotedAmount"
                         [controlName]="row['by_' + budgetYear].QuoteNumber"
                         [isDisabled]="ShouldRowCellBeLocked(row, budgetYear)"
                         [rightJustify]="true"
                         style="width:75px;display:inline-block"></comma-separated-input>
        </ng-container>

        <ng-container *ngIf="row['by_' + budgetYear] === undefined">
          <!-- no value -->
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="Columns; sticky: true"></tr>
    <tr mat-row
        *matRowDef="let row; columns: Columns"></tr>
  </table>
  <div class="quote-grid-document-ui">
      <em>Upload a document first using Documents section on top right. Only then you can add committed amounts in this grid.</em>
  </div>
</div>
