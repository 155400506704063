<mat-form-field>
	<mat-label>{{label}}</mat-label>
	<input matInput [class.comma-separated-input-right-justify]="rightJustify"
				 (focusout)="onFocusOut()"
				 #amountInput name="{{controlName}}" [(ngModel)]="amount"
				 pattern="[0-9]{0,10}(\.[0-9]{0,2})?"
				 [disabled]="isDisabled" style="display: none">
	<input matInput [class.comma-separated-input-right-justify]="rightJustify"
				 (focus)="onFocus()"
				 #amountDisplayInput name="{{controlName}}Display"
				 [disabled]="isDisabled" value="{{amount | number}}">

	<mat-error>
		Numerics only.  <mat-icon matTooltip="Value must be a positive whole or fractional number, with a max of 2 decimals and 10 whole-number digits.">info</mat-icon>
	</mat-error>

	<button mat-icon-button matSuffix *ngIf="deletable" type="button" (click)="delete()" [disabled]="isDeleteDisabled">
		<mat-icon>delete</mat-icon>
	</button>
</mat-form-field>
