<h1 mat-dialog-title>
    <ng-container>
        <span *ngIf="IsNewVendor">Create Vendor</span>
				<span *ngIf="!IsNewVendor">Edit Vendor - {{Vendor.Name}} ({{Vendor.Code}})</span>
    </ng-container>

    <div class="pull-right">
        <span>{{Vendor.Active ? "Active" : "Inactive"}}</span> &nbsp;
        <mat-slide-toggle color="accent" [disabled]="!Vendor.Id || !UserIsAdmin" [checked]="Vendor.Active" (change)="ToggleActive(vendorEditForm)">
        </mat-slide-toggle>
    </div>
</h1>

<div mat-dialog-content #modalContainer scrollInsetShadow>
    <form name="vendor-edit-form" #vendorEditForm="ngForm" style="padding-bottom: 15px;">
			<mat-tab-group [(selectedIndex)]="selectedTab">
				<mat-tab ngModelGroup="general" #generalTab="ngModelGroup">
					<ng-template mat-tab-label>
						<i *ngIf="TabHasErrors(generalTab)" class="fa fa-exclamation-circle" style="padding-right: 5px; color: red"></i>
						<span [style.color]="TabHasErrors(generalTab) ? 'red' : 'rgba(0,0,0,.87)'">General</span>
					</ng-template>
					<div class="row">
						<div class="col-sm-4">
							<mat-form-field>
								<input matInput required name="vendor-name" [disabled]="!UserIsAdmin" #vendorName="ngModel" placeholder="Name" [(ngModel)]="Vendor.Name">
								<mat-error *ngIf="vendorName.errors?.required">
									Vendor Name is <strong>required</strong>
								</mat-error>
							</mat-form-field>
						</div>
						<div class="col-sm-4">
							<mat-form-field>
								<input matInput name="code" placeholder="Code" [(ngModel)]="Vendor.Code" [disabled]="!UserIsAdmin" maxlength="20">
							</mat-form-field>
						</div>
						<div class="col-sm-4">
							<mat-form-field>
								<mat-label>Type</mat-label>
								<mat-select required [(ngModel)]="Vendor.VendorTypeId" #vendorType name="vendorType" [disabled]="!UserIsAdmin">
									<mat-option *ngFor="let s of Dropdowns.VendorTypes" [value]="s.TypeId">{{s.Description}}</mat-option>
									<mat-error *ngIf="vendorType.errors?.required">
										Vendor Type is required
									</mat-error>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-4">
							<div class="row">
								<div class="col-sm-12">
									<mat-form-field>
										<input matInput required #vendorAddress1="ngModel" name="address1" placeholder="Address 1" [(ngModel)]="Vendor.AddressLine1" [disabled]="!UserIsAdmin">
										<mat-error *ngIf="vendorAddress1.errors?.required">
											Address 1 is required
										</mat-error>
									</mat-form-field>
								</div>
								<div class="col-sm-12">
									<mat-form-field>
										<input matInput name="address2" placeholder="Address 2" [(ngModel)]="Vendor.AddressLine2" [disabled]="!UserIsAdmin">
									</mat-form-field>
								</div>
								<div class="col-sm-12">
									<mat-form-field>
										<input matInput name="address3" placeholder="Address 3" [(ngModel)]="Vendor.AddressLine3" [disabled]="!UserIsAdmin">
									</mat-form-field>
								</div>
							</div>
						</div>
						<div class="col-sm-8">
							<div class="row">
								<div class="col-sm-6">
									<mat-form-field *ngIf="UserIsAdmin">
										<mat-label>Country</mat-label>
										<ftms-typeahead #country name="country" [(ngModel)]="Vendor.CountryId" [required]="true" [items]="Dropdowns.Countries" (optionSelected)="onCountrySelected($event)"></ftms-typeahead>
										<mat-error *ngIf="country.errors?.required">
											Country is required
										</mat-error>
									</mat-form-field>
									<mat-form-field *ngIf="!UserIsAdmin">
										<mat-label>Country</mat-label>
										<mat-select [(ngModel)]="Vendor.CountryId" name="country" disabled>
											<mat-option *ngFor="let t of Dropdowns.Countries" [value]="t.Item">{{t.DisplayText}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-sm-6">
									<mat-form-field>
										<mat-label>State</mat-label>
										<ftms-typeahead *ngIf="UserIsAdmin" #vendorSubdivision name="subdivision" [(ngModel)]="Vendor.SubdivisionId" [items]="Dropdowns.Subdivisions"></ftms-typeahead>
										<mat-select *ngIf="!UserIsAdmin" [(ngModel)]="Vendor.SubdivisionId" disabled>
											<mat-option *ngFor="let t of Dropdowns.Subdivisions" [value]="t.Item">{{t.DisplayText}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6">
									<mat-form-field>
										<input matInput required name="city" #city="ngModel" placeholder="City" [(ngModel)]="Vendor.City" [disabled]="!UserIsAdmin">
										<mat-error *ngIf="city.errors?.required">
											City is <strong>required</strong>
										</mat-error>
									</mat-form-field>
								</div>
								<div class="col-sm-6">
									<mat-form-field>
										<input matInput name="zip" placeholder="Zip" [(ngModel)]="Vendor.Zip" [disabled]="!UserIsAdmin">
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div class="row">
						<div class="col-sm-4">
							<mat-form-field>
								<input matInput name="phone" placeholder="Phone" [(ngModel)]="Vendor.Phone" [disabled]="!UserIsAdmin">
							</mat-form-field>
						</div>
						<div class="col-sm-4">
							<mat-form-field>
								<input matInput name="fax" placeholder="Fax" [(ngModel)]="Vendor.Fax" [disabled]="!UserIsAdmin">
							</mat-form-field>
						</div>
						<div class="col-sm-4">
							<mat-form-field>
								<input matInput name="email" placeholder="Email" [(ngModel)]="Vendor.Email" [disabled]="!UserIsAdmin">
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-4">
							<mat-form-field>
								<mat-label>Purchase Order Required</mat-label>
								<mat-select required [(ngModel)]="Vendor.Po" #po name="Po" [disabled]="!UserIsAdmin">
									<mat-option *ngFor="let option of BooleanDropdown" [value]="option.value">{{option.name}}</mat-option>
								</mat-select>
								<mat-error *ngIf="po.errors?.required">
									PO is required
								</mat-error>
							</mat-form-field>
						</div>
						<div class="col-sm-4">
							<mat-form-field>
								<mat-label>Purchase Order Comment</mat-label>
								<input matInput name="PoComment" [(ngModel)]="Vendor.PoComment" [disabled]="!UserIsAdmin">
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-4">
							<mat-form-field>
								<mat-label>Agreement Required</mat-label>
								<mat-select required [disabled]="!UserIsAdmin" [(ngModel)]="Vendor.AgreementRequired" #agreementRequired name="agreementRequired" (selectionChange)="Vendor.AgreementNotRequiredReason = ''">
									<mat-option *ngFor="let option of BooleanDropdown" [value]="option.value">{{option.name}}</mat-option>
								</mat-select>
								<mat-error *ngIf="agreementRequired.errors?.required">
									Agreement Required is required
								</mat-error>
							</mat-form-field>
						</div>
						<div class="col-sm-4">
							<mat-form-field>
								<mat-label>If no, reason</mat-label>
								<input matInput name="agreementNotRequiredReason" [disabled]="Vendor.AgreementRequired || !UserIsAdmin" [required]="!Vendor.AgreementRequired" [(ngModel)]="Vendor.AgreementNotRequiredReason">
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-12">
							<mat-form-field>
								<mat-label>Comments</mat-label>
								<textarea matInput name="vendorComments" #vendorComments="ngModel" [(ngModel)]="Vendor.VendorComments" [disabled]="!UserIsAdmin"></textarea>
							</mat-form-field>
						</div>
					</div>
				</mat-tab>

				<!--Contact Tab-->
				<mat-tab ngModelGroup="{{contactGroup}}" #contactsTab="ngModelGroup">
					<ng-template mat-tab-label>
						<i *ngIf="TabHasErrors(contactsTab)" class="fa fa-exclamation-circle" style="padding-right: 5px; color: red"></i>
						<span [style.color]="TabHasErrors(contactsTab) ? 'red' : 'rgba(0,0,0,.87)'">Contacts</span>
					</ng-template>
					<div style="background-color:#eee">
						<mat-card *ngFor="let contact of Vendor.Contacts" style="margin: 5px 10px 10px;">
							<div class="row">
								<div class="col-sm-8">
									<mat-form-field>
										<input matInput required name="contact-name{{contact.RecId}}" #contactName="ngModel" placeholder="Name" [(ngModel)]="contact.Name" [disabled]="!UserIsAdmin">
										<mat-error *ngIf="contactName.errors?.required">
											Contact Name is <strong>required</strong>
										</mat-error>
									</mat-form-field>
								</div>
								<div class="col-sm-4">
									<section class="mat-checkbox-section pull-left" *ngIf="Vendor.Id != null">
										<mat-checkbox name="contact-active{{contact.RecId}}" [disabled]="!UserIsAdmin" [(ngModel)]="contact.Active">Active</mat-checkbox>
									</section>
									<button mat-icon-button type="button" (click)="RemoveContact(contact)" class="pull-right" [disabled]="!UserIsAdmin || contact.TrialAssociated">
										<mat-icon>delete</mat-icon>
									</button>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-4">
									<mat-form-field>
										<input matInput name="contact-phone{{contact.RecId}}" placeholder="Phone" [(ngModel)]="contact.Phone" [disabled]="!UserIsAdmin">
									</mat-form-field>
								</div>
								<div class="col-sm-4">
									<mat-form-field>
										<input matInput name="contact-fax{{contact.RecId}}" placeholder="Fax" [(ngModel)]="contact.Fax" [disabled]="!UserIsAdmin">
									</mat-form-field>
								</div>
								<div class="col-sm-4">
									<mat-form-field>
										<input matInput name="contact-email{{contact.RecId}}" placeholder="Email" [(ngModel)]="contact.Email" [disabled]="!UserIsAdmin">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-12">
									<mat-form-field>
										<input matInput required #contactAddress1="ngModel" name="contact-address1{{contact.RecId}}" placeholder="Address 1" [(ngModel)]="contact.AddressLine1" [disabled]="!UserIsAdmin">
										<mat-error *ngIf="contactAddress1.errors?.required">
											Address 1 is required
										</mat-error>
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-12">
									<mat-form-field>
										<input matInput name="contact-address2{{contact.RecId}}" placeholder="Address 2" [(ngModel)]="contact.AddressLine2" [disabled]="!UserIsAdmin">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-12">
									<mat-form-field>
										<input matInput name="contact-address3{{contact.RecId}}" placeholder="Address 3" [(ngModel)]="contact.AddressLine3" [disabled]="!UserIsAdmin">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-4">
									<mat-form-field>
										<mat-label>Country</mat-label>
										<mat-select [(ngModel)]="contact.CountryId" #country name="country{{contact.RecId}}" required disabled>
											<mat-option *ngFor="let s of Dropdowns.Countries" [value]="s.Item">{{s.DisplayText}}</mat-option>
										</mat-select>
										<mat-error *ngIf="country.errors?.required">
											Country is required
										</mat-error>
									</mat-form-field>
								</div>
								<div class="col-sm-4">
									<mat-form-field>
										<mat-label>State</mat-label>
										<ftms-typeahead *ngIf="UserIsAdmin" #subdivision name="subdivision{{contact.RecId}}" [(ngModel)]="contact.SubdivisionId" [items]="Dropdowns.Subdivisions"></ftms-typeahead>
										<mat-select *ngIf="!UserIsAdmin" [(ngModel)]="contact.SubdivisionId" disabled>
											<mat-option *ngFor="let t of Dropdowns.Subdivisions" [value]="t.Item">{{t.DisplayText}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-sm-4">
									<mat-form-field>
										<input matInput required name="contact-city{{contact.RecId}}" #contactCity="ngModel" placeholder="City" [(ngModel)]="contact.City" [disabled]="!UserIsAdmin">
										<mat-error *ngIf="contactCity.errors?.required">
											City is <strong>required</strong>
										</mat-error>
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-4">
									<mat-form-field>
										<input matInput name="zip{{contact.RecId}}" placeholder="Zip" [(ngModel)]="contact.Zip" [disabled]="!UserIsAdmin">
									</mat-form-field>
								</div>
							</div>
						</mat-card>
					</div>

					<button mat-raised-button type="button" (click)="AddContact()" [disabled]="!UserIsAdmin">
						<mat-icon>add</mat-icon>
						<b>&nbsp;Add Contact</b>
					</button>
				</mat-tab>

				<!--Agreement Tab-->
				<mat-tab ngModelGroup="agreements" #agreementsTab="ngModelGroup">
					<ng-template mat-tab-label>
						<i *ngIf="TabHasErrors(agreementsTab)" class="fa fa-exclamation-circle" style="padding-right: 5px; color: red"></i>
						<span [style.color]="TabHasErrors(agreementsTab) ? 'red' : 'rgba(0,0,0,.87)'">Agreements</span>
					</ng-template>

					<div class="row">
						<div class="col-sm-12">
							<button mat-raised-button type="button" class="pull-right" (click)="AddAgreement()" [disabled]="!Vendor.AgreementRequired || !UserIsAdmin||!Vendor.Active">
								<mat-icon>add</mat-icon>
								Add Agreement
							</button>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-12">
							<div class="mat-elevation-z8" style="margin-top: 8px;">
								<table mat-table class="mat-table-medium" [dataSource]="Agreements" style="max-height: 400px;">
									<ng-container matColumnDef="year">
										<th mat-header-cell *matHeaderCellDef>Year</th>
										<td mat-cell *matCellDef="let row" style="width: 80px;">{{row.CreatedDate | date:'y'}}</td>
									</ng-container>
									<ng-container matColumnDef="type">
										<th mat-header-cell *matHeaderCellDef>Type</th>
										<td mat-cell *matCellDef="let row" style="width: 90px;">{{row.AgreementTypeName}}</td>
									</ng-container>
									<ng-container matColumnDef="agreementNumber">
										<th mat-header-cell *matHeaderCellDef>Agreement #</th>
										<td mat-cell *matCellDef="let row" style="max-width: 350px;" matTooltip="{{row.AgreementNumber}}">
											{{row.AgreementNumber}}
											<span *ngIf="row.Active == true" class="badge badge-primary" style="margin-left: 10px;">Active</span>
										</td>
									</ng-container>
									<ng-container matColumnDef="from">
										<th mat-header-cell *matHeaderCellDef>From</th>
										<td mat-cell *matCellDef="let row" style="width: 110px;">{{row.EffectiveStartDate | date:'d-LLL-y'}}</td>
									</ng-container>
									<ng-container matColumnDef="to">
										<th mat-header-cell *matHeaderCellDef>To</th>
										<td mat-cell *matCellDef="let row" style="width: 110px;">{{row.EffectiveEndDate | date:'d-LLL-y'}}</td>
									</ng-container>
									<ng-container matColumnDef="paymentPlan">
										<th mat-header-cell *matHeaderCellDef>Payment Plan</th>
										<td mat-cell *matCellDef="let row" style="width: 110px;">{{row.PaymentPlanName}}</td>
									</ng-container>
									<ng-container matColumnDef="timeline">
										<th mat-header-cell *matHeaderCellDef>Timeline</th>
										<td mat-cell *matCellDef="let row" style="width: 60px;">{{row.Timeline}}</td>
									</ng-container>
									<ng-container matColumnDef="comments">
										<th mat-header-cell *matHeaderCellDef>Comments</th>
										<td mat-cell *matCellDef="let row" class="auto-ellipsis" style="max-width: 250px;" matTooltip="{{row.Comments}}">{{row.Comments}}</td>
									</ng-container>

									<tr mat-header-row *matHeaderRowDef="['year', 'type', 'agreementNumber', 'from', 'to', 'paymentPlan', 'timeline', 'comments']"></tr>
									<tr mat-row *matRowDef="let row; columns: ['year', 'type', 'agreementNumber', 'from', 'to', 'paymentPlan', 'timeline', 'comments'];"
											[class.selected]="agreementSelection.isSelected(row)"
											(click)="agreementSelection.toggle(row)"
											(dblclick)="EditAgreement(row)"></tr>
								</table>
							</div>
						</div>
					</div>
				</mat-tab>

				<!--Quotes Tab-->
				<mat-tab ngModelGroup="quotes" #quotesTab="ngModelGroup">
					<ng-template mat-tab-label>
						<i *ngIf="TabHasErrors(quotesTab)" class="fa fa-exclamation-circle" style="padding-right: 5px; color: red"></i>
						<span [style.color]="TabHasErrors(quotesTab) ? 'red' : 'rgba(0,0,0,.87)'">Quotes</span>
					</ng-template>

					<div class="row">
						<div class="col-sm-8">
							<h4>Vendor Quotes</h4>
							<vendor-quote-filter [VendorId]="Vendor.Id"
																	 (YearSelected)="onQuoteYearFilterSelected($event)"
																	 (FieldScientistSelected)="onFieldScientistFilterSelected($event)"
																	 (ContactSelected)="onContactFilterSelected($event)"></vendor-quote-filter>
						</div>
						<div class="col-sm-4">
							<button mat-raised-button type="button" class="pull-right" (click)="AddQuote()" [disabled]="!Vendor.Active||isFromQuote">
								<mat-icon>add</mat-icon>
								Create FSPS Quote
							</button>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-12">
							<div class="mat-elevation-z8" style="margin-top: 8px;">
								<table mat-table class="mat-table-medium" [dataSource]="Quotes">
									<ng-container matColumnDef="quoteNumber">
										<th mat-header-cell *matHeaderCellDef>Quote #</th>
										<td mat-cell *matCellDef="let row" style="width: 120px;">{{row.QuoteNumber}}</td>
									</ng-container>
									<ng-container matColumnDef="year">
										<th mat-header-cell *matHeaderCellDef>Year</th>
										<td mat-cell *matCellDef="let row" style="width: 60px;">{{row.Year}}</td>
									</ng-container>
									<ng-container matColumnDef="fieldScientist">
										<th mat-header-cell matTooltip=">Trial/Non-Trial Responsible" *matHeaderCellDef>Trial/Non-Trial Investigator</th>
										<td mat-cell *matCellDef="let row">{{row.FieldScientistName}}</td>
									</ng-container>
									<ng-container matColumnDef="vendorContact">
										<th mat-header-cell *matHeaderCellDef>Contact (Vendor)</th>
										<td mat-cell *matCellDef="let row" style="width: 110px;">{{row.ContactName}}</td>
									</ng-container>
									<ng-container matColumnDef="trialNonTrial">
										<th mat-header-cell *matHeaderCellDef>Trial/Non-Trial</th>
										<td mat-cell *matCellDef="let row" style="width: 110px;">{{ row.TrialCount ? row.TrialCount + " Trials" : null }}<br *ngIf="row.TrialCount && row.NontrialCount">{{ row.NontrialCount ? row.NontrialCount + " Non-Trials" : null }}</td>
									</ng-container>
									<ng-container matColumnDef="committed">
										<th mat-header-cell *matHeaderCellDef style="text-align: right">Total Committed</th>
										<td mat-cell *matCellDef="let row" style="max-width: 250px; text-align: right">{{row.TotalCommitted != null ? (row.TotalCommitted | number) + " " + row.CurrencyName : null}}</td>
									</ng-container>
									<ng-container matColumnDef="status">
										<th mat-header-cell *matHeaderCellDef>Status</th>
										<td mat-cell class="auto-ellipsis" *matCellDef="let row">
											<span title="{{GetQuoteStatus(row.Status)}}">
												{{GetQuoteStatus(row.Status)}}
											</span>
										</td>
									</ng-container>
									<ng-container matColumnDef="PR">
										<th mat-header-cell *matHeaderCellDef>PR</th>
										<td mat-cell *matCellDef="let row" style="max-width: 250px;">{{row.PurchaseRequisitionNumber}}</td>
									</ng-container>
									<ng-container matColumnDef="PO">
										<th mat-header-cell *matHeaderCellDef>PO</th>
										<td mat-cell *matCellDef="let row" style="max-width: 250px;">{{row.PurchaseOrderNumber}}</td>
									</ng-container>
									<ng-container matColumnDef="document">
										<th mat-header-cell *matHeaderCellDef style="text-align: right">Documents</th>
										<td mat-cell *matCellDef="let row" style="width: 110px; text-align: right">{{row.DocumentIds.length}}</td>
									</ng-container>
									<tr mat-header-row *matHeaderRowDef="['quoteNumber', 'year', 'fieldScientist', 'vendorContact', 'trialNonTrial', 'committed', 'status', 'PR', 'PO', 'document']"></tr>
									<tr mat-row
											*matRowDef="let row; columns: ['quoteNumber', 'year', 'fieldScientist', 'vendorContact', 'trialNonTrial', 'committed', 'status', 'PR', 'PO', 'document'];"
											(dblclick)="EditQuote(row.Id)"></tr>
								</table>
							</div>
						</div>
					</div>
				</mat-tab>

				<!--Invoice Tab-->
				<mat-tab ngModelGroup="invoice" #quotesTab="ngModelGroup">
					<ng-template mat-tab-label>
						<i *ngIf="TabHasErrors(invoiceTab)" class="fa fa-exclamation-circle" style="padding-right: 5px; color: red"></i>
						<span [style.color]="TabHasErrors(invoiceTab) ? 'red' : 'rgba(0,0,0,.87)'">Invoice/Payment</span>
					</ng-template>

					<div class="row">
						<div class="col-sm-8">
							<h4>Vendor Invoice/Payment</h4>
							<invoice-filter [VendorId]="Vendor.Id"
															(YearSelected)="onInvoiceYearFilterSelected($event)"
															(PurchaseOrderSelected)="onPurchaseOrderFilterSelected($event)"
															(PurchaseRequisitionSelected)="onPurchaseRequisitionFilterSelected($event)"></invoice-filter>
						</div>
						<div class="col-sm-4">
							<button mat-raised-button class="pull-right" type="button" (click)="AddInvoice()" [disabled]="!Vendor.Active || !UserIsAdmin||isFromInvoice">
								<mat-icon>add</mat-icon>
								Create FSPS Invoice
							</button>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-12">
							<div class="mat-elevation-z8" style="margin-top: 8px;">
								<table mat-table class="mat-table-medium" [dataSource]="Invoices">
									<ng-container matColumnDef="invoiceNumber">
										<th mat-header-cell *matHeaderCellDef>Invoice #</th>
										<td mat-cell *matCellDef="let row">{{row.InvoiceNumber}}</td>
									</ng-container>
									<ng-container matColumnDef="po">
										<th mat-header-cell *matHeaderCellDef>PO</th>
										<td mat-cell *matCellDef="let row">{{row.PurchaseOrderNumber}}</td>
									</ng-container>
									<ng-container matColumnDef="pr">
										<th mat-header-cell *matHeaderCellDef>PR</th>
										<td mat-cell *matCellDef="let row">{{row.PurchaseRequisitionNumber}}</td>
									</ng-container>
									<ng-container matColumnDef="poEnd">
										<th mat-header-cell *matHeaderCellDef>PO End</th>
										<td mat-cell *matCellDef="let row">
											<ng-container *ngIf="row.QuotePurchaseOrderEndDates.length == 1">
												{{row.QuotePurchaseOrderEndDates[0] | date:'d-LLL-y'}}
											</ng-container>
											<ng-container *ngIf="row.QuotePurchaseOrderEndDates.length > 1">
												<em>Multiple</em>
											</ng-container>

										</td>
									</ng-container>
									<ng-container matColumnDef="vendorInvoiceNumber">
										<th mat-header-cell *matHeaderCellDef>Vendor Inv #</th>
										<td mat-cell *matCellDef="let row">{{row.VendorAssignedInvoiceNumber}}</td>
									</ng-container>
									<ng-container matColumnDef="dueDate">
										<th mat-header-cell *matHeaderCellDef>Due</th>
										<td mat-cell *matCellDef="let row">{{row.DueDate | date:'d-LLL-y'}}</td>
									</ng-container>
									<ng-container matColumnDef="invoiceTotal">
										<th mat-header-cell *matHeaderCellDef>Inv Total</th>
										<td mat-cell *matCellDef="let row">{{row.TotalInvoiced}} {{row.CurrencyName}}</td>
									</ng-container>
									<ng-container matColumnDef="checkOrWireNumber">
										<th mat-header-cell *matHeaderCellDef>Check/Wire</th>
										<td mat-cell *matCellDef="let row">{{row.PaymentCheck}}</td>
									</ng-container>
									<ng-container matColumnDef="paidAmount">
										<th mat-header-cell *matHeaderCellDef>Paid</th>
										<td mat-cell *matCellDef="let row">{{row.PaymentDate | date:'d-LLL-y'}}</td>
									</ng-container>
									<ng-container matColumnDef="status">
										<th mat-header-cell *matHeaderCellDef>Status</th>
										<td mat-cell *matCellDef="let row">{{GetInvoiceStatusDescription(row.InvoiceStatus)}}</td>
									</ng-container>
									<tr mat-header-row *matHeaderRowDef="['invoiceNumber', 'po', 'pr', 'poEnd', 'vendorInvoiceNumber', 'dueDate', 'invoiceTotal', 'checkOrWireNumber', 'paidAmount', 'status']"></tr>
									<tr mat-row
											*matRowDef="let row; columns: ['invoiceNumber', 'po', 'pr', 'poEnd', 'vendorInvoiceNumber', 'dueDate', 'invoiceTotal', 'checkOrWireNumber', 'paidAmount', 'status'];"
											(dblclick)="EditInvoice(row.Id)"></tr>
								</table>
							</div>
						</div>
					</div>
				</mat-tab>
			</mat-tab-group>
    </form>
</div>

<div mat-dialog-actions style="display: flex">
	<div *ngIf="!IsNewVendor">
		<dl class="dl-horizontal modal-audit-info">
			<dt *ngIf="Vendor.CreatedByName != undefined && Vendor.CreatedByPersonId != 0">Created:</dt>
			<dd *ngIf="Vendor.CreatedByName != undefined && Vendor.CreatedByPersonId != 0">{{ Vendor.CreateDate | date:'d-LLL-y' }} by {{ Vendor.CreatedByName }}</dd>
			<dt *ngIf="Vendor.UpdatedByName != undefined && Vendor.UpdatedByPersonId != 0">Updated:</dt>
			<dd *ngIf="Vendor.UpdatedByName != undefined && Vendor.UpdatedByPersonId != 0">{{ Vendor.UpdateDate | date:'d-LLL-y' }} by {{ Vendor.UpdatedByName }}</dd>
		</dl>
	</div>

	<span style="flex: 1 1 auto;"></span>
	<button mat-raised-button color="accent" (click)="CancelModal()">Cancel</button>
	<button mat-raised-button color="primary" (click)="SaveVendor(vendorEditForm, false)">Save</button>
	<button mat-raised-button color="primary" (click)="SaveVendor(vendorEditForm, true)">Save &amp; Close</button>
</div>

<style>
  .mat-column-status {
    max-width: 90px !important;
  }
</style>
